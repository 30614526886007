<template>
<div class="container">
  <vue-horizontal class="horizontal" snap="center" scroll :button="false">
      <section class="about-me">
        <about-me/>      
      </section>
      <section>
        <div class="project-container">
          <div class="top-text">
            <a href="theleague.run">Sunrun Recruiting Website</a>
            <p class="grey">November 2021</p>
          </div>        
        <img src="../assets/sunrun-recruiting.png" alt="A screenshot of a recruiting website for Sunrun.">
        </div>
      </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
      <section> <placeholder-tile/> </section>
    </vue-horizontal>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal'
import AboutMe from './AboutMe.vue'
import PlaceholderTile from './PlaceholderTile.vue'

export default {
  components: {
    VueHorizontal,
    AboutMe,
    PlaceholderTile
    },
  data() {
    return {
      reactiveVariable: 'hello!',
      items: [1, 2, 3]
    };
  },
  name: 'ScrollTest',
  props: {
    msg: String
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.horizontal {
align-items: center;
width: 100vw;
margin: auto;
}
section {
  display: flex;
  align-items: center;
  width: 60vw;
  margin-right: 15vw;
  border-radius: 6px;
  height: 95vh;
}
section div {
  width: 100%;
}
.about-me {
  width: 100vw;
  background: none;
}

.horizontal >>> .v-hl-container::-webkit-scrollbar {  
  height: 16px;
  width: 16px;
  background: transparent;
  overflow-x: visible;
}

.horizontal >>> .v-hl-container::-webkit-scrollbar-track {
  overflow-x: visible;
  background: rgba(199, 204, 210, 0.3);
  border-radius: 5px;
  margin: 0 4vw;
}

.horizontal >>> .v-hl-container::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 4px;
  background: #B6BEC6;
  -webkit-box-shadow: 0 6px 6px rgba(0,0,0,.1);
}
img {
  max-width: 60vw;  
  border-radius: 6px;
}
.project-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
