<template>
  <div id="app">
    <div class="container">
    <ScrollTest/>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ScrollTest from './components/ScrollTest.vue';

export default {
  name: 'App',
  components: {
    ScrollTest,
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Newsreader:ital,wght@0,300;0,400;1,300;1,400&display=swap');


body { margin: 0px; background-color: #FBFCFD; overflow: hidden; }
#app {
  font-family: "Inter", Helvetica, Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(106.08deg, #FFFFFF 0%, #F3F5F7 100.47%);
  height: 100vh;
}
.bolded {
  font-weight: 500;
}
.serif-font {
  font-family: "Newsreader", sans-serif;
  font-style: italic;
  font-size: 18px;
}
.grey {
  color: #707071;
}
a {
    text-decoration: underline;
    -webkit-text-decoration-color: #B6BEC6; /* Safari */  
    text-decoration-color: #B6BEC6;
    text-decoration-thickness: 2px;
    color: black;
    transition: 0.15s ease;
  }
  a:hover {
    -webkit-text-decoration-color: #6B7681; /* Safari */  
    text-decoration-color: #6B7681;
  }
</style>
