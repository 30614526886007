<template>
    <div class="content-container">
        <p class="bolded">Tanner Thelin</p>
        <br>
        <p><span class="serif-font">Human interfaces.</span> Building software that's more fun to use. Thinking about interactions, visual organization, and UI frameworks. Senior Designer at <a href="www.bridger.work">Bridger</a> building digital experiences for forward-thinking companies.</p>
        <div class="three-column">
          <div class="column">
            <p class="bolded grey">Building</p>
            <div class="project">
              <a href="#">Open Late</a>
              <p class="grey">A WIP design collective [lorem ipsum dolor sit amet].</p>
            </div>
            <div class="project">
              <a href="#">Teeny Tiny</a>
              <p class="grey">A minimal image compressor for designers.</p>
            </div>
            <div class="project">
              <a href="#">Untitled Movie Logging App</a>
              <p class="grey">A dead-simple iOS app to track the movies you watch.</p>
            </div>
          </div>
          <div class="column">
            <p class="bolded grey">Writing</p>
              <div class="project">
                <a href="#">Why Design Twitter is Stupid</a>
                <p class="grey">I'm sick of hearing about web3.</p>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AboutMe',
  props: {
    
  }
}
</script>

<style scoped>
.content-container{
  text-align: left;
  max-width: 600px;
  margin: auto;
  }
  .three-column {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .column {
    width: 40%;
  }
  .project {
    margin-top: 30px;
  }
  .project p {
    margin: 0px;
  }  
</style>