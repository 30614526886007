<template>
    <div class="content-container">
        <h2>Placeholder Tile</h2>
        <p>Content will go here...</p>
    </div>
</template>

<script>
export default {
  name: 'PlaceholderTile',
  props: {
    
  }
}
</script>

<style scoped>
.content-container {
    text-align: center;
    background: rgba(199, 204, 210, 0.3);
    border-radius: 6px;
    height: 50vh;
}
</style>